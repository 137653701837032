import React from 'react';
import { getVideos } from 'actions/liveChatActions';
import LoadingVideos from 'components/LoadingVideos';
import LoggedInHeader from 'components/LoggedInHeader';
import VideoCard from 'components/VideoCard';
import VideoGrid from 'components/VideoGrid';
import PATHNAMES from 'constants/pathnames';
import './LiveChatVideos.scss';

const LiveChatVideos = () => {
  const [videos, setVideos] = React.useState([]);
  const loading = videos.length === 0;

  React.useEffect(() => {
    (async () => {
      setVideos(await getVideos());
    })();
  }, []);

  return (
    <div className="live-chat-videos">
      <LoggedInHeader />
      {loading ? (
        <LoadingVideos />
      ) : (
        <VideoGrid>
          {videos.map((metadata) => (
            <VideoCard
              key={metadata.path}
              metadata={metadata}
              to={PATHNAMES.WATCH_LIVE_CHAT.replace(':id', metadata.id)}
            />
          ))}
        </VideoGrid>
      )}
    </div>
  );
};

export default LiveChatVideos;
