import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingBox = () => (
  <div style={{ display: 'flex', justifyContent: 'center', padding: '4em' }}>
    <CircularProgress />
  </div>
);

export default LoadingBox;
