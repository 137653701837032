import ConfirmationSwitch from 'components/ConfirmationSwitch';
import React from 'react';

const AdminField = (props) => (
  <ConfirmationSwitch
    label="Admin"
    color="primary"
    inputProps={{ 'aria-label': 'admin' }}
    confirmDialog={{
      title: 'Are you sure?',
      message: (value) =>
        value
          ? 'Are you sure you want to grant this user admin access?'
          : "Are you sure you want to remove this user's admin access?",
      confirm: 'Yes',
      cancel: 'Cancel',
    }}
    {...props}
  />
);

export default AdminField;
