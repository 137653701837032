import React from 'react';
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import './DateTimePicker.scss';

const DateTimePicker = (props) => (
  <div className="date-time-picker">
    <MuiDateTimePicker
      inputVariant="outlined"
      format="ccc MM/dd/yyyy h:mm a ZZZZ"
      {...props}
    />
  </div>
);

export default DateTimePicker;
