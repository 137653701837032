import React from 'react';
import { withRouter } from 'react-router-dom';
import { getVideo, getDescription } from 'actions/videoActions';
import BackLink from 'components/BackLink';
import LoadingBox from 'components/LoadingBox';
import LoggedInHeader from 'components/LoggedInHeader';
import VideoPlayer from 'components/VideoPlayer';
import PATHNAMES from 'constants/pathnames';
import './WatchVideo.scss';

const Videos = ({ match }) => {
  const [video, setVideo] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const loading = !video;
  const { id } = match.params;

  React.useEffect(() => {
    (async () => {
      setVideo(await getVideo(id));
    })();
  }, [id]);

  React.useEffect(() => {
    (async () => {
      if (video) {
        setDescription(
          await getDescription(`/content/${video.path}/description.html`)
        );
      }
    })();
  }, [video]);

  return (
    <div className="watch-video">
      <LoggedInHeader showTitle={false} />
      <div className="video-wrapper">
        {loading ? (
          <LoadingBox />
        ) : (
          <>
            <BackLink to={PATHNAMES.VIDEOS} />
            <VideoPlayer
              videoOptions={{
                sources: [
                  {
                    src: `/content/${video.path}/index.m3u8`,
                    type: 'application/x-mpegURL',
                  },
                ],
              }}
            />
            <h3 className="name">{video.name}</h3>
            {description && (
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(Videos);
