import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import AccountsAdmin from 'components/AccountsAdmin';
import AccountSettings from 'components/AccountSettings';
import ActivationCodesAdmin from 'components/ActivationCodesAdmin';
import EditAccount from 'components/EditAccount';
import EditNutrition from 'components/EditNutrition';
import ForgotPassword from 'components/ForgotPassword';
import LiveChatVideos from 'components/LiveChatVideos';
import LoadingBox from 'components/LoadingBox';
import Login from 'components/Login';
import Nutrition from 'components/Nutrition';
import NutritionAdmin from 'components/NutritionAdmin';
import ResetForgotPassword from 'components/ResetForgotPassword';
import SignUp from 'components/SignUp';
import VerifyLoggedIn from 'components/VerifyLoggedIn';
import Videos from 'components/Videos';
import WatchLiveChatVideo from 'components/WatchLiveChatVideo';
import WatchVideo from 'components/WatchVideo';
import PATHNAMES from 'constants/pathnames';
import scopes from 'utils/scopes';

const withLoginVerification = (node) => (
  <VerifyLoggedIn renderLoading={() => <LoadingBox />}>{node}</VerifyLoggedIn>
);

const withAdminVerification = (node) => (
  <VerifyLoggedIn verifyAdmin={true} renderLoading={() => <LoadingBox />}>
    {node}
  </VerifyLoggedIn>
);

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={PATHNAMES.LOGIN} component={() => <Login />} />
        <Route exact path={PATHNAMES.SIGN_UP} component={() => <SignUp />} />
        <Route
          exact
          path={PATHNAMES.FORGOT_PASSWORD}
          component={() => <ForgotPassword />}
        />
        <Route
          exact
          path={PATHNAMES.RESET_FORGOT_PASSWORD}
          component={() => <ResetForgotPassword />}
        />

        {/* USER ROUTES */}
        <Route
          exact
          path={PATHNAMES.ACCOUNT_SETTINGS}
          component={() => withLoginVerification(<AccountSettings />)}
        />
        <Route
          exact
          path={PATHNAMES.LIVE_CHAT_VIDEOS}
          component={() => withLoginVerification(<LiveChatVideos />)}
        />
        <Route
          path={PATHNAMES.WATCH_LIVE_CHAT}
          component={() => withLoginVerification(<WatchLiveChatVideo />)}
        />
        <Route
          exact
          path={PATHNAMES.NUTRITION}
          component={() => withLoginVerification(<Nutrition />)}
        />
        <Route
          exact
          path={PATHNAMES.VIDEOS}
          component={() => withLoginVerification(<Videos />)}
        />
        <Route
          path={PATHNAMES.WATCH}
          component={() => withLoginVerification(<WatchVideo />)}
        />

        {/* ADMIN ROUTES */}
        <Route
          exact
          path={PATHNAMES.ACCOUNTS_ADMIN}
          component={() => withAdminVerification(<AccountsAdmin />)}
        />
        <Route
          path={PATHNAMES.ACTIVATION_CODES_ADMIN}
          component={() => withAdminVerification(<ActivationCodesAdmin />)}
        />
        <Route
          path={PATHNAMES.EDIT_ACCOUNT}
          component={() => withAdminVerification(<EditAccount />)}
        />
        <Route
          exact
          path={PATHNAMES.NUTRITION_ADMIN}
          component={() => withAdminVerification(<NutritionAdmin />)}
        />
        <Route
          path={PATHNAMES.EDIT_NUTRITION}
          component={() => withAdminVerification(<EditNutrition />)}
        />

        {/* DEFAULT/FALLBACK ROUTES */}
        <Route
          exact
          path={PATHNAMES.DEFAULT}
          component={() => {
            if (scopes.hasVideo()) {
              return <Redirect to={PATHNAMES.VIDEOS} />;
            } else if (scopes.hasNutrition()) {
              return <Redirect to={PATHNAMES.NUTRITION} />;
            } else {
              return <Redirect to={PATHNAMES.LOGIN} />;
            }
          }}
        />
        <Route component={() => <Redirect to={PATHNAMES.DEFAULT} />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
