import React from 'react';
import { Link } from 'react-router-dom';
import orderBy from 'lodash-es/orderBy';
import DataTable from '../DataTable';
import PATHNAMES from 'constants/pathnames';
import './AccountsTable.scss';

const AccountsTable = ({ data }) => {
  const tableData = data.map(({ firstName, lastName, scopes, ...rest }) => ({
    name: (
      <Link
        to={PATHNAMES.EDIT_ACCOUNT.replace(':id', rest.id)}
      >{`${lastName}, ${firstName}`}</Link>
    ),
    admin: scopes.includes('ADMIN') ? '✔︎' : '',
    ...rest,
  }));

  return (
    <div className="accounts-table">
      <DataTable
        columns={[
          { title: 'NAME', field: 'name' },
          { title: 'EMAIL', field: 'email' },
          { title: 'STATUS', field: 'status' },
          { title: 'ADMIN', field: 'admin' },
        ]}
        data={orderBy(tableData, ['name'])}
        options={{ showTitle: false }}
        style={{ margin: '1em' }}
      />
    </div>
  );
};

export default AccountsTable;
