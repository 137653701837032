import ConfirmationSwitch from 'components/ConfirmationSwitch';
import React from 'react';

const ActiveAccountField = (props) => (
  <ConfirmationSwitch
    label="Active Account?"
    color="primary"
    inputProps={{ 'aria-label': 'active' }}
    confirmDialog={{
      title: 'Are you sure?',
      message: (value) =>
        value
          ? 'Are you sure you want to make this account active? The user will gain access to Dauntless Academy.'
          : 'Are you sure you want to make this account inactive? The user will lose access to Dauntless Academy.',
      confirm: 'Yes',
      cancel: 'Cancel',
    }}
    {...props}
  />
);

export default ActiveAccountField;
