import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import PasswordRequirementsButton from 'components/PasswordRequirementsButton';
import TextField from 'components/TextField';

const Password = ({ value, showRequirements, ...rest }) => (
  <TextField
    type="password"
    InputProps={{
      ...(showRequirements && {
        endAdornment: (
          <InputAdornment position="end">
            <PasswordRequirementsButton />
          </InputAdornment>
        ),
      }),
    }}
    {...rest}
  />
);

export default Password;
