import memoize from 'lodash-es/memoize';
import ApiError from 'errors/ApiError';
import api from 'utils/api';

export const getVideos = memoize(async () => {
  try {
    const { data } = await api.get('/live-chat');
    return data;
  } catch {
    throw new ApiError();
  }
});

export const getVideo = async (id) => {
  try {
    const { data } = await api.get(`/live-chat/${id}`);
    return data;
  } catch {
    throw new ApiError();
  }
};
