import React from 'react';
import { withRouter } from 'react-router-dom';
import PrimaryButton from 'components/PrimaryButton';
import PATHNAMES from 'constants/pathnames';

const SignUpButton = ({ history, staticContext, ...rest }) => (
  <PrimaryButton
    onClick={() => history.push(PATHNAMES.SIGN_UP)}
    style={{ minWidth: '100px' }}
    {...rest}
  >
    SIGN UP
  </PrimaryButton>
);

export default withRouter(SignUpButton);
