import React from 'react';
import videojs from 'video.js';

require('video.js/dist/video-js.css');

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    const options = {
      autoplay: true,
      controls: true,
      fluid: true,
      ...this.props.videoOptions,
    };

    // instantiate Video.js
    this.player = videojs(this.videoNode, options, this.onPlayerReady);
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  onPlayerReady() {
    console.log('onPlayerReady', this);
    videojs.log('foo bar');
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div>
        <div data-vjs-player>
          <video
            ref={(node) => (this.videoNode = node)}
            className="video-js"
          ></video>
        </div>
      </div>
    );
  }
}
