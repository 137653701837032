import React from 'react';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import './DatePicker.scss';

const DatePicker = (props) => (
  <div className="date-picker">
    <MuiDatePicker inputVariant="outlined" format="ccc MM/dd/yyyy" {...props} />
  </div>
);

export default DatePicker;
