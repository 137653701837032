import REGEXES from 'constants/regexes';
import * as yup from 'yup';

export default {
  activationCode: () =>
    yup.string().matches(REGEXES.ACTIVATION_CODE).required('Required'),
  confirmPassword: () =>
    yup
      .string()
      .matches(REGEXES.PASSWORD)
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  date: () => yup.string().matches(REGEXES.DATE).required('Required'),
  datetime: () => yup.string().matches(REGEXES.DATETIME).required('Required'),
  email: () => yup.string().email().required('Required'),
  feedback: () => yup.string().matches(REGEXES.FEEDBACK).required('Required'),
  firstName: () =>
    yup.string().matches(REGEXES.FIRST_NAME).required('Required'),
  lastName: () => yup.string().matches(REGEXES.LAST_NAME).required('Required'),
  nutritionComment: () =>
    yup.string().matches(REGEXES.NUTRITION_COMMENT).required('Required'),
  password: () => yup.string().matches(REGEXES.PASSWORD).required('Required'),
  url: () => yup.string().matches(REGEXES.URL).required('Required'),
};
