const getScopes = () => {
  try {
    return JSON.parse(sessionStorage.getItem('scopes') || '[]');
  } catch (error) {
    return [];
  }
};

export default {
  isAdmin: (scopes = getScopes()) => !!scopes.includes('ADMIN'),
  hasNutrition: (scopes = getScopes()) => !!scopes.includes('NUTRITION'),
  hasVideo: (scopes = getScopes()) => !!scopes.includes('VIDEO'),
};
