import React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { forgotPassword } from 'actions/accountActions';
import Header from 'components/Header';
import PrimaryButton from 'components/PrimaryButton';
import ShadowBox from 'components/ShadowBox';
import SignUpButton from 'components/SignUpButton';
import TextField from 'components/TextField';
import VerifyLoggedOut from 'components/VerifyLoggedOut';
import PATHNAMES from 'constants/pathnames';
import AccountNotFoundError from 'errors/AccountNotFoundError';
import validators from 'utils/validators';
import * as yup from 'yup';
import './ForgotPassword.scss';

const VALIDATION_SCHEMA = yup.object().shape({
  email: validators.email(),
});

const ForgotPassword = () => {
  const [Error, setError] = React.useState(null);
  const [emailSent, setEmailSent] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setError(null);
      await forgotPassword(values.email);
      setEmailSent(true);
    } catch (error) {
      setError(() => () => (
        <div className="error-message error-label">
          {error instanceof AccountNotFoundError
            ? 'We were unable to locate an account with this email address'
            : 'Something went wrong -- try again!'}
        </div>
      ));
    }
  };

  return (
    <div className="forgot-password">
      <Header showTitle={true} buttons={<SignUpButton />} />
      <ShadowBox>
        <h3>FORGOT PASSWORD?</h3>
        <VerifyLoggedOut>
          {emailSent ? (
            <div className="check-email">Thank you - check your email!</div>
          ) : (
            <>
              {Error && <Error />}
              <Formik
                initialValues={{ email: '' }}
                onSubmit={handleSubmit}
                validationSchema={VALIDATION_SCHEMA}
              >
                {({
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      name="email"
                      label="Email"
                      value={values.email}
                      disabled={isSubmitting}
                      error={
                        errors.email &&
                        touched.email &&
                        'Please enter a valid email address'
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <div className="cta-wrapper">
                      <div className="back-link">
                        <Link to={PATHNAMES.LOGIN}>« Back</Link>
                      </div>
                      <PrimaryButton
                        className="submit-button"
                        type="submit"
                        loading={isSubmitting}
                        disabled={isSubmitting || !dirty}
                      >
                        SEND RESET LINK
                      </PrimaryButton>
                    </div>
                  </form>
                )}
              </Formik>
            </>
          )}
        </VerifyLoggedOut>
      </ShadowBox>
    </div>
  );
};

export default ForgotPassword;
