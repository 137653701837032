import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import orderBy from 'lodash-es/orderBy';
import DataTable from '../DataTable';
import PATHNAMES from 'constants/pathnames';
import './NutritionTable.scss';

const NutritionTable = ({ data }) => {
  const tableData = data.map(({ name, ...rest }) => ({
    name: (
      <Link
        to={PATHNAMES.EDIT_NUTRITION.replace(':id', rest.id)}
      >{`${name} (${rest.startDate})`}</Link>
    ),
    formattedPublishDateTime: DateTime.fromISO(rest.publishDateTime, {
      zone: 'America/New_York',
    }).toFormat('ccc, LLL d h:mm a ZZZZ'),
    ...rest,
  }));

  return (
    <div className="nutrition-table">
      <DataTable
        columns={[
          { title: 'NAME', field: 'name' },
          { title: 'PUBLISH DATE/TIME', field: 'formattedPublishDateTime' },
        ]}
        data={orderBy(tableData, ['publishDateTime'])}
        options={{ showTitle: false }}
        style={{ margin: '1em' }}
      />
    </div>
  );
};

export default NutritionTable;
