import React from 'react';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { withRouter } from 'react-router-dom';
import { getNutrition, updateNutrition } from 'actions/adminActions';
import DatePicker from 'components/DatePicker';
import DateTimePicker from 'components/DateTimePicker';
import LoadingBox from 'components/LoadingBox';
import LoggedInHeader from 'components/LoggedInHeader';
import PrimaryButton from 'components/PrimaryButton';
import TextField from 'components/TextField';
import validators from 'utils/validators';
import * as yup from 'yup';
import './EditNutrition.scss';

const VALIDATION_SCHEMA = yup.object().shape({
  startDate: validators.date(),
  publishDateTime: validators.datetime(),
  liveChatDateTime: validators.datetime(),
  liveChatUrl: validators.url(),
});

const EditNutrition = ({ match }) => {
  const [nutrition, setNutrition] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        setNutrition(await getNutrition(match.params.id));
      } catch (err) {
        console.error('FAILED TO LOAD NUTRITION!');
      }
    })();
  }, [match.params.id]);

  const handleSubmit = async (values) => {
    await updateNutrition(nutrition.id, values);
    setNutrition(values);
  };

  if (!nutrition) return <LoadingBox />;

  return (
    <div className="edit-nutrition">
      <LoggedInHeader />
      <h3>NUTRITION</h3>
      <Formik
        initialValues={nutrition}
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField name="id" label="ID" value={values.id} disabled={true} />
            <TextField
              name="name"
              label="Name"
              value={values.name}
              disabled={true}
            />
            <DatePicker
              name="startDate"
              label="Start Date"
              value={DateTime.fromISO(values.startDate, {
                zone: 'America/New_York',
              })}
              onChange={(datetime) =>
                setFieldValue('startDate', datetime.toISODate())
              }
            />
            <DateTimePicker
              name="publishDateTime"
              label="Publish Date/Time"
              value={DateTime.fromISO(values.publishDateTime)}
              onChange={(datetime) =>
                setFieldValue(
                  'publishDateTime',
                  datetime.toUTC().toISO({ suppressMilliseconds: true })
                )
              }
            />
            <DateTimePicker
              name="liveChatDateTime"
              label="Live Chat Date/Time"
              value={DateTime.fromISO(values.liveChatDateTime)}
              onChange={(datetime) =>
                setFieldValue(
                  'liveChatDateTime',
                  datetime.toUTC().toISO({ suppressMilliseconds: true })
                )
              }
            />
            <TextField
              name="liveChatUrl"
              label="Live Chat URL"
              value={values.liveChatUrl}
              disabled={isSubmitting}
              error={
                errors.liveChatUrl &&
                touched.liveChatUrl &&
                'Please enter a valid URL'
              }
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <PrimaryButton
              className="submit-button"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || !dirty}
            >
              UPDATE
            </PrimaryButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(EditNutrition);
