import MuiSwitch from '@material-ui/core/Switch';
import React from 'react';
import './Switch.scss';

const Switch = ({ label, onChange, ...rest }) => {
  const handleChange = (event) => onChange(event.target.checked);

  return (
    <div className="switch">
      <div className="label">{label}</div>
      <MuiSwitch {...rest} onChange={handleChange} />
    </div>
  );
};

export default Switch;
