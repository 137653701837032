import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { requestAccess } from 'actions/accountActions';
import PrimaryButton from 'components/PrimaryButton';
import React from 'react';
import './RequestAccessModal.scss';

const RequestAccessModal = ({
  product,
  title,
  message,
  accessRequestedMessage,
  open,
  onClose,
}) => {
  const [accessRequested, setAccessRequested] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleRequestAccess = async () => {
    try {
      setLoading(true);
      await requestAccess(product);
      setAccessRequested(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      className="request-access-modal"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="request-access-dialog-title"
      open={open}
    >
      <DialogTitle className="request-access-dialog-title">
        <h3>{title}</h3>
      </DialogTitle>
      <DialogContent dividers>
        {accessRequested ? accessRequestedMessage : message}
      </DialogContent>
      <DialogActions>
        {accessRequested ? (
          <Button autoFocus onClick={onClose} color="primary">
            Close
          </Button>
        ) : (
          <>
            <Button disabled={loading} onClick={onClose} color="primary">
              Cancel
            </Button>
            <PrimaryButton
              type="submit"
              disabled={loading}
              loading={loading}
              onClick={handleRequestAccess}
            >
              Request access
            </PrimaryButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RequestAccessModal;
