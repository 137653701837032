import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PATHNAMES from 'constants/pathnames';
import './Header.scss';

const Header = ({ showLogo, showTitle, buttons }) => (
  <div className="header">
    <div className={classNames('nav-bar', { 'with-logo': showLogo })}>
      {showLogo && (
        <Link to={PATHNAMES.DEFAULT}>
          <img alt="logo" src="/images/logo-small.png" />
        </Link>
      )}
      {buttons && <div className="buttons-wrapper">{buttons}</div>}
    </div>
    {showTitle && <h1>DAUNTLESS ACADEMY</h1>}
  </div>
);

export default Header;
