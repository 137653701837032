import React from 'react';
import classNames from 'classnames';

const SignUpErrorMessage = ({ className }) => (
  <div className={classNames(className, 'sign-up-error', 'error-label')}>
    Something went wrong -- try again!
  </div>
);

export default SignUpErrorMessage;
