import React from 'react';
import { Formik } from 'formik';
import PrimaryButton from 'components/PrimaryButton';
import SavedBadge from 'components/SavedBadge';
import ShadowBox from 'components/ShadowBox';
import TextField from 'components/TextField';
import ExistingAccountError from 'errors/ExistingAccountError';
import validators from 'utils/validators';
import * as yup from 'yup';

const VALIDATION_SCHEMA = yup.object().shape({
  email: validators.email(),
});

const ChangeEmail = ({ value, onChange = () => {} }) => {
  const [Error, setError] = React.useState(null);
  const [saved, setSaved] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setError(null);
      await onChange({ email: values.email });
      setSaved(true);
    } catch (error) {
      setError(() => () => (
        <div className="error-message error-label">
          {error instanceof ExistingAccountError
            ? 'An account already exists with this email address'
            : 'Something went wrong -- try again!'}
        </div>
      ));
    }
  };

  return (
    <ShadowBox>
      <h3>EMAIL</h3>
      {Error && <Error />}
      <Formik
        initialValues={{ email: value }}
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              name="email"
              label="Email"
              value={values.email}
              disabled={isSubmitting}
              error={
                errors.email &&
                touched.email &&
                'Please enter a valid email address'
              }
              onBlur={handleBlur}
              onChange={(event) => {
                setSaved(false);
                handleChange(event);
              }}
            />
            {dirty && (
              <PrimaryButton
                className="submit-button"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !dirty}
              >
                SAVE
              </PrimaryButton>
            )}
            {saved && <SavedBadge />}
          </form>
        )}
      </Formik>
    </ShadowBox>
  );
};

export default ChangeEmail;
