import Chip from '@material-ui/core/Chip';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import DoneIcon from '@material-ui/icons/Done';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import React from 'react';
import './VideoFilters.scss';

const VideoFilters = ({ filters, onToggle }) => (
  <div className="video-filters">
    <Chip
      className="filter"
      icon={<AccessibilityIcon />}
      label="MOBILITY"
      clickable
      color="primary"
      variant={filters.MOBILITY ? 'default' : 'outlined'}
      onClick={() => onToggle('MOBILITY')}
      {...(filters.MOBILITY && {
        deleteIcon: <DoneIcon />,
        onDelete: () => onToggle('MOBILITY'),
      })}
    />
    <Chip
      className="filter"
      icon={<FitnessCenterIcon />}
      label="WORKOUT"
      clickable
      color="primary"
      variant={filters.WORKOUT ? 'default' : 'outlined'}
      onClick={() => onToggle('WORKOUT')}
      {...(filters.WORKOUT && {
        deleteIcon: <DoneIcon />,
        onDelete: () => onToggle('WORKOUT'),
      })}
    />
  </div>
);

export default VideoFilters;
