import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import './PasswordRequirementsButton.scss';

const PasswordRequirementsButton = () => {
  const [open, setOpen] = useState(false);

  const tooltip = (
    <div className="password-requirements-tooltip">
      <h3>Password Requirements</h3>
      <ul>
        <li>At least 8-characters</li>
        <li>One or more lowercase characters</li>
        <li>One or more uppercase characters</li>
        <li>One or more numbers</li>
        <li>One or more special characters</li>
      </ul>
    </div>
  );

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Tooltip
          title={tooltip}
          arrow
          onClose={() => setOpen(false)}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <IconButton
            aria-label="View password requirements"
            edge="end"
            size="small"
            onClick={() => setOpen(true)}
            tabIndex="-1"
          >
            <HelpOutline />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default PasswordRequirementsButton;
