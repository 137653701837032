import React from 'react';
import { withRouter } from 'react-router-dom';
import LoadingBox from 'components/LoadingBox';
import { checkLoggedIn } from 'actions/accountActions';
import PATHNAMES from 'constants/pathnames';

const VerifyLoggedOut = ({ children, history }) => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const loggedIn = await checkLoggedIn();
      if (loggedIn) {
        history.push(PATHNAMES.DEFAULT);
      } else {
        setLoading(false);
      }
    })();
  }, [history]);

  return loading ? <LoadingBox /> : children;
};

export default withRouter(VerifyLoggedOut);
