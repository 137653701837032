import ApiError from 'errors/ApiError';
import ExistingAccountError from 'errors/ExistingAccountError';
import HTTP_STATUS from 'http-status-codes';
import get from 'lodash-es/get';
import api from 'utils/api';

export const createActivationCode = async (scopes) => {
  try {
    const { data } = await api.post(`/admin/activation-code`, { scopes });
    return data;
  } catch (error) {
    throw new ApiError();
  }
};

export const getAccounts = async () => {
  try {
    const { data } = await api.get(`/admin/accounts`);
    return data;
  } catch (error) {
    throw new ApiError();
  }
};

export const getAccount = async (id) => {
  try {
    const { data } = await api.get(`/admin/accounts/${id}`);
    return data;
  } catch (error) {
    throw new ApiError();
  }
};

export const updateAccount = async (id, data = {}) => {
  try {
    await api.put(`/admin/accounts/${id}`, data);
  } catch (error) {
    const status = get(error, 'response.status');

    if (status === HTTP_STATUS.CONFLICT) {
      throw new ExistingAccountError();
    } else {
      throw new ApiError();
    }
  }
};

export const getActivationCodes = async () => {
  try {
    const { data } = await api.get(`/admin/activation-code`);
    return data;
  } catch (error) {
    throw new ApiError();
  }
};

export const getAllNutrition = async () => {
  try {
    const { data } = await api.get(`/admin/nutrition`);
    return data;
  } catch (error) {
    throw new ApiError();
  }
};

export const getNutrition = async (id) => {
  try {
    const { data } = await api.get(`/admin/nutrition/${id}`);
    return data;
  } catch (error) {
    throw new ApiError();
  }
};

export const updateNutrition = async (id, data = {}) => {
  try {
    await api.put(`/admin/nutrition/${id}`, data);
  } catch (error) {
    throw new ApiError();
  }
};
