import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from 'components/Switch';
import isNil from 'lodash-es/isNil';
import React from 'react';

const ConfirmationDialog = ({ onClose, value, open, confirmDialog = {} }) => {
  const handleCancel = () => onClose();

  const handleYes = () => onClose(value);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        {confirmDialog.title}
      </DialogTitle>
      <DialogContent dividers>{confirmDialog.message(value)}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {confirmDialog.cancel}
        </Button>
        <Button onClick={handleYes} color="primary">
          {confirmDialog.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmationSwitch = ({ onChange, confirmDialog, ...rest }) => {
  const [open, setOpen] = React.useState(false);

  const handleSwitchChange = () => setOpen(true);

  const handleClose = (newValue) => {
    setOpen(false);

    if (!isNil(newValue)) {
      onChange(newValue);
    }
  };

  return (
    <div className="confirmation-switch">
      <Switch {...rest} onChange={handleSwitchChange} />
      <ConfirmationDialog
        keepMounted
        open={open}
        onClose={handleClose}
        value={!rest.checked}
        confirmDialog={confirmDialog}
      />
    </div>
  );
};

export default ConfirmationSwitch;
