import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './SavedBadge.scss';

const SavedBadge = () => {
  return (
    <div className="saved-badge">
      <SvgIcon component={CheckCircleIcon} style={{ color: '#4caf50' }} />{' '}
      Saved!
    </div>
  );
};

export default SavedBadge;
