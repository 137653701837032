import React from 'react';
import { withRouter } from 'react-router-dom';
import { getVideo } from 'actions/liveChatActions';
import BackLink from 'components/BackLink';
import LoadingBox from 'components/LoadingBox';
import LoggedInHeader from 'components/LoggedInHeader';
import VideoPlayer from 'components/VideoPlayer';
import PATHNAMES from 'constants/pathnames';
import './WatchLiveChatVideo.scss';

const WatchLiveChatVideo = ({ match }) => {
  const [video, setVideo] = React.useState(null);
  const loading = !video;
  const { id } = match.params;

  React.useEffect(() => {
    (async () => {
      setVideo(await getVideo(id));
    })();
  }, [id]);

  return (
    <div className="watch-live-chat-video">
      <LoggedInHeader showTitle={false} />
      <div className="video-wrapper">
        {loading ? (
          <LoadingBox />
        ) : (
          <>
            <BackLink to={PATHNAMES.LIVE_CHAT_VIDEOS} />
            <VideoPlayer
              videoOptions={{
                sources: [
                  {
                    src: `/content/${video.path}/index.m3u8`,
                    type: 'application/x-mpegURL',
                  },
                ],
              }}
            />
            <h3 className="name">{video.name}</h3>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(WatchLiveChatVideo);
