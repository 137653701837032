import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import Router from 'components/Router';
import Theme from 'components/Theme';

const App = () => {
  return (
    <Theme>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <Router />
      </MuiPickersUtilsProvider>
    </Theme>
  );
};

export default App;
