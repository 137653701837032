import React from 'react';
import { getAllNutrition } from 'actions/adminActions';
import NutritionTable from 'components/NutritionTable';
import LoadingBox from 'components/LoadingBox';
import LoggedInHeader from 'components/LoggedInHeader';
import './NutritionAdmin.scss';

const NutritionAdmin = () => {
  const [nutrition, setNutrition] = React.useState([]);
  const loading = nutrition.length === 0;

  React.useEffect(() => {
    (async () => {
      setNutrition(await getAllNutrition());
    })();
  }, []);

  return (
    <div className="nutrition-admin">
      <LoggedInHeader />
      <h3>NUTRITION</h3>
      {loading ? <LoadingBox /> : <NutritionTable data={nutrition} />}
    </div>
  );
};

export default NutritionAdmin;
