import HTTP_STATUS from 'http-status-codes';
import get from 'lodash-es/get';
import AccountNotFoundError from 'errors/AccountNotFoundError';
import ExistingAccountError from 'errors/ExistingAccountError';
import InvalidActivationCodeError from 'errors/InvalidActivationCodeError';
import InvalidLoginError from 'errors/InvalidLoginError';
import ApiError from 'errors/ApiError';
import api from 'utils/api';
import scopeChecker from 'utils/scopes';

const addAuthorizationHeader = (token) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const removeAuthorizationHeader = () => {
  api.defaults.headers.common['Authorization'] = undefined;
};

const setLoggedIn = (id, token, scopes) => {
  sessionStorage.setItem('id', id);
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('scopes', JSON.stringify(scopes));
  addAuthorizationHeader(token);
};

const setLoggedOut = () => {
  sessionStorage.removeItem('id');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('scopes');
  removeAuthorizationHeader();
};

export const createAccount = async (payload) => {
  try {
    const { data } = await api.post('/accounts', payload);
    setLoggedIn(data.id, data.token, data.scopes);
  } catch (error) {
    const status = get(error, 'response.status');

    if (status === HTTP_STATUS.CONFLICT) {
      throw new ExistingAccountError();
    } else if (status === HTTP_STATUS.PAYMENT_REQUIRED) {
      throw new InvalidActivationCodeError();
    } else {
      throw new ApiError();
    }
  }
};

export const login = async (email, password) => {
  try {
    const { data } = await api.post('/login', {
      email,
      password,
    });

    setLoggedIn(data.id, data.token, data.scopes);
  } catch (error) {
    const status = get(error, 'response.status');

    if (status === HTTP_STATUS.UNAUTHORIZED) {
      throw new InvalidLoginError();
    } else {
      throw new ApiError();
    }
  }
};

export const logout = () => {
  setLoggedOut();
};

export const forgotPassword = async (email) => {
  try {
    await api.post('/forgot-password', { email });
  } catch (error) {
    const status = get(error, 'response.status');

    if (status === HTTP_STATUS.BAD_REQUEST) {
      throw new AccountNotFoundError();
    } else {
      throw new ApiError();
    }
  }
};

export const checkLoggedIn = async (verifyAdmin) => {
  try {
    const id = sessionStorage.getItem('id');
    const token = sessionStorage.getItem('token');
    const scopes = JSON.parse(sessionStorage.getItem('scopes'));

    if (!id || !token || (verifyAdmin && !scopeChecker.isAdmin(scopes))) {
      return false;
    }

    const { data } = await api.get(`/accounts/${id}/verify-token`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (verifyAdmin && !scopeChecker.isAdmin(data.scopes)) {
      throw new Error('Admin verification failed');
    }

    setLoggedIn(id, token, scopes);
    return true;
  } catch (error) {
    setLoggedOut();
    return false;
  }
};

export const updateAccount = async (data = {}, options = {}) => {
  try {
    const id = options.id || sessionStorage.getItem('id');
    const token = options.token || sessionStorage.getItem('token');

    await api.put(`/accounts/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    const status = get(error, 'response.status');

    if (status === HTTP_STATUS.CONFLICT) {
      throw new ExistingAccountError();
    } else {
      throw new ApiError();
    }
  }
};

export const getAccount = async () => {
  try {
    const id = sessionStorage.getItem('id');
    const { data } = await api.get(`/accounts/${id}`);
    return data;
  } catch (error) {
    throw new ApiError();
  }
};

export const requestAccess = async (product) => {
  try {
    const id = sessionStorage.getItem('id');
    const { data } = await api.get(
      `/accounts/${id}/request-access?product=${product}`
    );
    return data;
  } catch (error) {
    const status = get(error, 'response.status');

    if (status === HTTP_STATUS.BAD_REQUEST) {
      throw new AccountNotFoundError();
    } else {
      throw new ApiError();
    }
  }
};

export const submitFeedback = async (feedback) => {
  try {
    const id = sessionStorage.getItem('id');
    const { data } = await api.post(`/accounts/${id}/feedback`, { feedback });
    return data;
  } catch (error) {
    const status = get(error, 'response.status');

    if (status === HTTP_STATUS.BAD_REQUEST) {
      throw new AccountNotFoundError();
    } else {
      throw new ApiError();
    }
  }
};
