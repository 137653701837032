import React from 'react';
import Header from 'components/Header';
import MenuDrawer from 'components/MenuDrawer';

const LoggedInHeader = (props) => (
  <Header
    showLogo={true}
    showTitle={true}
    buttons={<MenuDrawer />}
    {...props}
  />
);

export default LoggedInHeader;
