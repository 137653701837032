import React from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { createAccount } from 'actions/accountActions';
import ExistingAccountMessage from 'components/ExistingAccountMessage';
import InvalidActivationCodeMessage from 'components/InvalidActivationCodeMessage';
import Header from 'components/Header';
import LoginButton from 'components/LoginButton';
import Password from 'components/Password';
import PrimaryButton from 'components/PrimaryButton';
import ShadowBox from 'components/ShadowBox';
import TextField from 'components/TextField';
import VerifyLoggedOut from 'components/VerifyLoggedOut';
import SignUpErrorMessage from 'components/SignUpErrorMessage';
import PATHNAMES from 'constants/pathnames';
import ExistingAccountError from 'errors/ExistingAccountError';
import InvalidActivationCodeError from 'errors/InvalidActivationCodeError';
import validators from 'utils/validators';
import * as yup from 'yup';
import './SignUp.scss';

const VALIDATION_SCHEMA = yup.object().shape({
  firstName: validators.firstName(),
  lastName: validators.lastName(),
  email: validators.email(),
  password: validators.password(),
  confirmPassword: validators.confirmPassword(),
  activationCode: validators.activationCode(),
});

const SignUp = ({ history }) => {
  const [Error, setError] = React.useState(null);

  const handleSubmit = async (values) => {
    try {
      setError(null);
      await createAccount({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        activationCode: values.activationCode,
      });
      history.push(PATHNAMES.DEFAULT);
    } catch (error) {
      setError(() => {
        if (error instanceof ExistingAccountError)
          return (props) => (
            <ExistingAccountMessage {...props} history={history} />
          );
        else if (error instanceof InvalidActivationCodeError)
          return (props) => <InvalidActivationCodeMessage {...props} />;
        return (props) => <SignUpErrorMessage {...props} />;
      });
    }
  };

  return (
    <div className="sign-up">
      <Header showTitle={true} buttons={<LoginButton />} />
      <ShadowBox>
        <h3>SIGN UP</h3>
        <VerifyLoggedOut>
          {Error && <Error className="error-message" />}
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              confirmPassword: '',
              activationCode: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={VALIDATION_SCHEMA}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  name="firstName"
                  label="First Name"
                  value={values.firstName}
                  disabled={isSubmitting}
                  error={
                    errors.firstName &&
                    touched.firstName &&
                    'Please enter a valid first name'
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  name="lastName"
                  label="Last Name"
                  value={values.lastName}
                  disabled={isSubmitting}
                  error={
                    errors.lastName &&
                    touched.lastName &&
                    'Please enter a valid last name'
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  disabled={isSubmitting}
                  error={
                    errors.email &&
                    touched.email &&
                    'Please enter a valid email address'
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Password
                  name="password"
                  label="Password"
                  value={values.password}
                  disabled={isSubmitting}
                  error={
                    errors.password &&
                    touched.password &&
                    'Please enter a valid password'
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  showRequirements={true}
                />
                <Password
                  name="confirmPassword"
                  label="Confirm Password"
                  value={values.confirmPassword}
                  disabled={isSubmitting}
                  error={
                    errors.confirmPassword &&
                    touched.confirmPassword &&
                    'Password is invalid or does not match'
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  name="activationCode"
                  label="Activation Code"
                  value={values.activationCode}
                  disabled={isSubmitting}
                  error={
                    errors.activationCode &&
                    touched.activationCode &&
                    'Please enter a valid activation code'
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="cta-wrapper">
                  <PrimaryButton
                    className="submit-button"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || !dirty}
                  >
                    SIGN UP
                  </PrimaryButton>
                  <a
                    className="get-activation-code-link external"
                    href="https://www.dauntlessnh.com/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get an activation code!
                  </a>
                </div>
              </form>
            )}
          </Formik>
        </VerifyLoggedOut>
      </ShadowBox>
    </div>
  );
};

export default withRouter(SignUp);
