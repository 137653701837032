import Alert from '@material-ui/lab/Alert';
import { createActivationCode } from 'actions/adminActions';
import PrimaryButton from 'components/PrimaryButton';
import ShadowBox from 'components/ShadowBox';
import Switch from 'components/Switch';
import { Formik } from 'formik';
import union from 'lodash-es/union';
import without from 'lodash-es/without';
import React from 'react';
import './GenerateActivationCode.scss';

const GenerateActivationCode = () => {
  const [activationCode, setActivationCode] = React.useState(null);
  const [error, setError] = React.useState(null);

  const handleSubmit = async ({ scopes }) => {
    try {
      setActivationCode(null);
      setError(null);

      if (scopes.length === 0) {
        setError('Please select at least one product');
        return;
      }

      const { activationCode } = await createActivationCode(scopes);
      setActivationCode(activationCode);
    } catch (error) {
      setError('Something went wrong -- try again!');
    }
  };

  return (
    <ShadowBox className="generate-activation-code">
      <h3>GENERATE NEW ACTIVATION CODE</h3>
      <Formik initialValues={{ scopes: [] }} onSubmit={handleSubmit}>
        {({ values, isSubmitting, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Switch
              label="Video"
              color="primary"
              inputProps={{ 'aria-label': 'video' }}
              checked={values.scopes.includes('VIDEO')}
              onChange={(value) => {
                if (value) {
                  setFieldValue('scopes', union(values.scopes, ['VIDEO']));
                } else {
                  setFieldValue('scopes', without(values.scopes, 'VIDEO'));
                }
              }}
            />
            <Switch
              label="Nutrition"
              color="primary"
              inputProps={{ 'aria-label': 'nutrition' }}
              checked={values.scopes.includes('NUTRITION')}
              onChange={(value) => {
                if (value) {
                  setFieldValue('scopes', union(values.scopes, ['NUTRITION']));
                } else {
                  setFieldValue('scopes', without(values.scopes, 'NUTRITION'));
                }
              }}
            />
            <PrimaryButton
              className="submit-button"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              GENERATE
            </PrimaryButton>
          </form>
        )}
      </Formik>
      {activationCode && (
        <Alert className="alert" severity="success">
          Activation Code: <strong>{activationCode}</strong>
        </Alert>
      )}
      {error && (
        <Alert className="alert" severity="error">
          {error}
        </Alert>
      )}
    </ShadowBox>
  );
};

export default GenerateActivationCode;
