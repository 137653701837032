import React from 'react';
import { getAccounts } from 'actions/adminActions';
import AccountsTable from 'components/AccountsTable';
import LoadingBox from 'components/LoadingBox';
import LoggedInHeader from 'components/LoggedInHeader';
import './AccountsAdmin.scss';

const AccountsAdmin = () => {
  const [accounts, setAccounts] = React.useState([]);
  const loading = accounts.length === 0;

  React.useEffect(() => {
    (async () => {
      setAccounts(await getAccounts());
    })();
  }, []);

  return (
    <div className="accounts-admin">
      <LoggedInHeader />
      <h3>ACCOUNTS</h3>
      {loading ? <LoadingBox /> : <AccountsTable data={accounts} />}
    </div>
  );
};

export default AccountsAdmin;
