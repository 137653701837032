import React from 'react';
import { withRouter } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EcoIcon from '@material-ui/icons/Eco';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import LockIcon from '@material-ui/icons/Lock';
import MenuIcon from '@material-ui/icons/Menu';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SettingsIcon from '@material-ui/icons/Settings';
import YouTubeIcon from '@material-ui/icons/YouTube';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { logout } from 'actions/accountActions';
import PATHNAMES from 'constants/pathnames';
import RequestAccessModal from 'components/RequestAccessModal';
import scopes from 'utils/scopes';
import stopPropagation from 'utils/stopPropagation';
import './MenuDrawer.scss';

const MenuDrawer = ({ history }) => {
  const [open, setOpen] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [nutritionOpen, setNutritionOpen] = React.useState(false);
  const [addNutritionModalOpen, setAddNutritionModalOpen] = React.useState(
    false
  );
  const [addVideoModalOpen, setAddVideoModalOpen] = React.useState(false);

  React.useEffect(() => setAdmin(scopes.isAdmin()), []);

  const toggleDrawer = (toggle) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(toggle);
  };

  const handleLogOut = () => {
    logout();
    history.push(PATHNAMES.LOGIN);
  };

  const handleNavigation = (pathname) => () => history.push(pathname);

  const navigateToExternalLink = (url) => () => window.open(url, '_blank');

  return (
    <>
      <IconButton aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        className="menu-drawer"
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <h3>Dauntless Academy</h3>
        <Divider />
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem
              button
              onClick={
                scopes.hasVideo()
                  ? handleNavigation(PATHNAMES.VIDEOS)
                  : () => setAddVideoModalOpen(true)
              }
            >
              <ListItemIcon>
                <YouTubeIcon className="video-icon" />
              </ListItemIcon>
              <ListItemText primary="Videos" />
              {!scopes.hasVideo() && (
                <ListItemIcon>
                  <LockIcon />
                </ListItemIcon>
              )}
            </ListItem>
            <ListItem
              button
              onClick={stopPropagation(() => setNutritionOpen(!nutritionOpen))}
            >
              <ListItemIcon>
                <EcoIcon className="nutrition-icon" />
              </ListItemIcon>
              <ListItemText primary="Nutrition" />
              {nutritionOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={nutritionOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={
                    scopes.hasNutrition()
                      ? handleNavigation(PATHNAMES.NUTRITION)
                      : () => setAddNutritionModalOpen(true)
                  }
                >
                  <ListItemText className="inset" primary="Meal Plans" />
                  {!scopes.hasNutrition() && (
                    <ListItemIcon>
                      <LockIcon />
                    </ListItemIcon>
                  )}
                </ListItem>
                <ListItem
                  button
                  onClick={
                    scopes.hasNutrition()
                      ? handleNavigation(PATHNAMES.LIVE_CHAT_VIDEOS)
                      : () => setAddNutritionModalOpen(true)
                  }
                >
                  <ListItemText
                    className="inset"
                    primary="Live Chat Recordings"
                  />
                  {!scopes.hasNutrition() && (
                    <ListItemIcon>
                      <LockIcon />
                    </ListItemIcon>
                  )}
                </ListItem>
              </List>
            </Collapse>
          </List>
          {admin && (
            <>
              <Divider />
              <List>
                <ListItem
                  button
                  onClick={stopPropagation(() => setAdminOpen(!adminOpen))}
                >
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  <ListItemText primary="Admin" />
                  {adminOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={adminOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      onClick={handleNavigation(PATHNAMES.ACCOUNTS_ADMIN)}
                    >
                      <ListItemText className="inset" primary="Accounts" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={handleNavigation(
                        PATHNAMES.ACTIVATION_CODES_ADMIN
                      )}
                    >
                      <ListItemText
                        className="inset"
                        primary="Activation Codes"
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={handleNavigation(PATHNAMES.NUTRITION_ADMIN)}
                    >
                      <ListItemText className="inset" primary="Nutrition" />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </>
          )}
          <Divider />
          <List>
            <ListItem
              button
              onClick={handleNavigation(PATHNAMES.ACCOUNT_SETTINGS)}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Account Settings" />
            </ListItem>
            <ListItem
              button
              onClick={navigateToExternalLink(
                'http://dauntlessnh.com/contact-us'
              )}
            >
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Contact Us" />{' '}
              <ListItemIcon>
                <OpenInNewIcon />
              </ListItemIcon>
            </ListItem>
            <ListItem button onClick={handleLogOut}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </List>
        </div>
        <div style={{ flex: 1 }} />
        <h6>BE FEARLESS</h6>
      </SwipeableDrawer>
      {addVideoModalOpen && (
        <RequestAccessModal
          product="VIDEO"
          title="DAUNTLESS VIDEO"
          message="Would you like to add video?"
          accessRequestedMessage="Great! We will be in contact with you shortly!"
          open={addVideoModalOpen}
          onClose={() => setAddVideoModalOpen(false)}
        />
      )}
      {addNutritionModalOpen && (
        <RequestAccessModal
          product="NUTRITION"
          title="DAUNTLESS NUTRITION"
          message="Would you like to add nutrition?"
          accessRequestedMessage="Great! We will be in contact with you shortly!"
          open={addNutritionModalOpen}
          onClose={() => setAddNutritionModalOpen(false)}
        />
      )}
    </>
  );
};

export default withRouter(MenuDrawer);
