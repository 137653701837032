import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const PrimaryButton = ({
  children,
  className,
  type = 'button',
  loading = false,
  ...rest
}) => (
  <Button
    className={classNames(className)}
    type={type}
    variant="contained"
    color="primary"
    {...rest}
  >
    {loading ? <CircularProgress color="secondary" size="24px" /> : children}
  </Button>
);

export default PrimaryButton;
