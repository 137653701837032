import React from 'react';
import { Formik } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { login } from 'actions/accountActions';
import Header from 'components/Header';
import InvalidLoginMessage from 'components/InvalidLoginMessage';
import Password from 'components/Password';
import PrimaryButton from 'components/PrimaryButton';
import ShadowBox from 'components/ShadowBox';
import SignUpButton from 'components/SignUpButton';
import TextField from 'components/TextField';
import VerifyLoggedOut from 'components/VerifyLoggedOut';
import PATHNAMES from 'constants/pathnames';
import validators from 'utils/validators';
import * as yup from 'yup';
import './Login.scss';
import pathnames from 'constants/pathnames';

const VALIDATION_SCHEMA = yup.object().shape({
  email: validators.email(),
  password: validators.password(),
});

const Login = ({ history }) => {
  const [Error, setError] = React.useState(null);

  const handleSubmit = async (values) => {
    try {
      await login(values.email, values.password);
      history.push(PATHNAMES.DEFAULT);
    } catch (error) {
      setError(() => () => <InvalidLoginMessage history={history} />);
    }
  };

  return (
    <div className="login">
      <Header showTitle={true} buttons={<SignUpButton />} />
      <ShadowBox>
        <h3>LOG IN</h3>
        <VerifyLoggedOut>
          {Error && <Error />}
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={handleSubmit}
            validationSchema={VALIDATION_SCHEMA}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  disabled={isSubmitting}
                  error={
                    errors.email &&
                    touched.email &&
                    'Please enter a valid email address'
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Password
                  name="password"
                  label="Password"
                  value={values.password}
                  disabled={isSubmitting}
                  error={
                    errors.password &&
                    touched.password &&
                    'Please enter a valid password'
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="cta-wrapper">
                  <PrimaryButton
                    className="submit-button"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || !dirty}
                  >
                    LOG IN
                  </PrimaryButton>
                  <Link
                    className="forgot-password-link"
                    to={pathnames.FORGOT_PASSWORD}
                  >
                    Forgot your password?
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </VerifyLoggedOut>
      </ShadowBox>
    </div>
  );
};

export default withRouter(Login);
