import React from 'react';
import SkeletonCard from 'components/SkeletonCard';
import VideoGrid from 'components/VideoGrid';

const LoadingVideos = () => (
  <>
    <VideoGrid>
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
    </VideoGrid>
  </>
);

export default LoadingVideos;
