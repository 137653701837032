import Paper from '@material-ui/core/Paper';
import { getComments } from 'actions/nutritionActions';
import AddComment from 'components/AddComment';
import LoadingBox from 'components/LoadingBox';
import { DateTime } from 'luxon';
import React from 'react';
import './NutritionComments.scss';

const formatTimestamp = (timestamp) =>
  DateTime.fromISO(timestamp).toFormat('ccc, LLL d h:mm a');

const formatMessage = (message) =>
  message.split('\n').map((item, key) => {
    return (
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    );
  });

const NutritionComments = ({ id }) => {
  const [comments, setComments] = React.useState(null);
  const loading = comments === null;

  React.useEffect(() => {
    (async () => {
      setComments(await getComments(id));
    })();
  }, [id]);

  return (
    <div className="nutrition-comments">
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <AddComment id={id} onAdd={setComments} />
          {comments.map(({ AUTHOR, ID, MESSAGE, TIMESTAMP }) => (
            <Paper key={ID} className="comment">
              <div className="comment-header">
                <strong>{AUTHOR}</strong> - {formatTimestamp(TIMESTAMP)}
              </div>
              <div className="comment-body">{formatMessage(MESSAGE)}</div>
            </Paper>
          ))}
        </div>
      )}
    </div>
  );
};

export default NutritionComments;
