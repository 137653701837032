import React from 'react';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';
import PATHNAMES from 'constants/pathnames';

const ExistingAccountMessage = ({ className, history }) => (
  <div
    className={classNames(className, 'account-already-exists', 'error-label')}
  >
    <span>An account with that email address already exists.</span>
    <br />
    <span>
      <Link className="log-in" onClick={() => history.push(PATHNAMES.LOGIN)}>
        Click here to log in
      </Link>
    </span>
  </div>
);

export default ExistingAccountMessage;
