import memoize from 'lodash-es/memoize';
import ApiError from 'errors/ApiError';
import api from 'utils/api';

export const getNutrition = memoize(async () => {
  try {
    const { data } = await api.get('/nutrition');
    return data;
  } catch {
    throw new ApiError();
  }
});

export const getComments = async (id) => {
  try {
    const { data } = await api.get(`/nutrition/${id}/comments`);
    return data;
  } catch {
    throw new ApiError();
  }
};

export const createComment = async (id, message) => {
  try {
    const { data } = await api.post(`/nutrition/${id}/comments`, {
      authorId: sessionStorage.getItem('id'),
      message,
    });
    return data;
  } catch {
    throw new ApiError();
  }
};
