import React from 'react';
import { Formik } from 'formik';
import union from 'lodash-es/union';
import without from 'lodash-es/without';
import { withRouter } from 'react-router-dom';
import { getAccount, updateAccount } from 'actions/adminActions';
import ActiveAccountField from 'components/ActiveAccountField';
import AdminField from 'components/AdminField';
import LoadingBox from 'components/LoadingBox';
import LoggedInHeader from 'components/LoggedInHeader';
import PrimaryButton from 'components/PrimaryButton';
import Switch from 'components/Switch';
import TextField from 'components/TextField';
import validators from 'utils/validators';
import * as yup from 'yup';
import './EditAccount.scss';

const VALIDATION_SCHEMA = yup.object().shape({
  email: validators.email(),
  firstName: validators.firstName(),
  lastName: validators.lastName(),
});

const EditAccount = ({ match }) => {
  const [account, setAccount] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        setAccount(await getAccount(match.params.id));
      } catch (err) {
        console.error('FAILED TO LOAD ACCOUNT!');
      }
    })();
  }, [match.params.id]);

  const handleSubmit = async (values) => {
    await updateAccount(account.id, values);
    setAccount(values);
  };

  if (!account) return <LoadingBox />;

  return (
    <div className="edit-account">
      <LoggedInHeader />
      <h3>ACCOUNT</h3>
      <Formik
        initialValues={account}
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              name="firstName"
              label="First Name"
              value={values.firstName}
              disabled={isSubmitting}
              error={
                errors.firstName &&
                touched.firstName &&
                'Please enter a valid first name'
              }
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              name="lastName"
              label="Last Name"
              value={values.lastName}
              disabled={isSubmitting}
              error={
                errors.lastName &&
                touched.lastName &&
                'Please enter a valid last name'
              }
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              name="email"
              label="Email"
              value={values.email}
              disabled={isSubmitting}
              error={
                errors.email &&
                touched.email &&
                'Please enter a valid email address'
              }
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <ActiveAccountField
              checked={values.status === 'ACTIVE'}
              onChange={(value) =>
                setFieldValue('status', value ? 'ACTIVE' : 'INACTIVE')
              }
            />
            <Switch
              label="Video"
              color="primary"
              inputProps={{ 'aria-label': 'video' }}
              checked={values.scopes.includes('VIDEO')}
              onChange={(value) => {
                if (value) {
                  setFieldValue('scopes', union(values.scopes, ['VIDEO']));
                } else {
                  setFieldValue('scopes', without(values.scopes, 'VIDEO'));
                }
              }}
            />
            <Switch
              label="Nutrition"
              color="primary"
              inputProps={{ 'aria-label': 'nutrition' }}
              checked={values.scopes.includes('NUTRITION')}
              onChange={(value) => {
                if (value) {
                  setFieldValue('scopes', union(values.scopes, ['NUTRITION']));
                } else {
                  setFieldValue('scopes', without(values.scopes, 'NUTRITION'));
                }
              }}
            />
            <AdminField
              checked={values.scopes.includes('ADMIN')}
              onChange={(value) => {
                if (value) {
                  setFieldValue('scopes', union(values.scopes, ['ADMIN']));
                } else {
                  setFieldValue('scopes', without(values.scopes, 'ADMIN'));
                }
              }}
            />
            <PrimaryButton
              className="submit-button"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || !dirty}
            >
              UPDATE
            </PrimaryButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(EditAccount);
