import React from 'react';
import { Formik } from 'formik';
import Password from 'components/Password';
import PrimaryButton from 'components/PrimaryButton';
import SavedBadge from 'components/SavedBadge';
import ShadowBox from 'components/ShadowBox';
import validators from 'utils/validators';
import * as yup from 'yup';

const VALIDATION_SCHEMA = yup.object().shape({
  password: validators.password(),
  confirmPassword: validators.confirmPassword(),
});

const ChangePassword = ({ onChange = () => {} }) => {
  const [Error, setError] = React.useState(null);
  const [saved, setSaved] = React.useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setError(null);
      await onChange({ password: values.password });
      resetForm();
      setSaved(true);
    } catch (error) {
      resetForm();
      setError(() => () => (
        <div className="error-message error-label">
          Something went wrong -- try again!
        </div>
      ));
    }
  };

  return (
    <ShadowBox>
      <h3>PASSWORD</h3>
      {Error && <Error />}
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Password
              name="password"
              label="New Password"
              value={values.password}
              disabled={isSubmitting}
              error={
                errors.password &&
                touched.password &&
                'Please enter a valid password'
              }
              onBlur={handleBlur}
              onChange={(event) => {
                setSaved(false);
                handleChange(event);
              }}
            />
            <Password
              name="confirmPassword"
              label="Confirm New Password"
              value={values.confirmPassword}
              disabled={isSubmitting}
              error={
                errors.confirmPassword &&
                touched.confirmPassword &&
                'Password is invalid or does not match'
              }
              onBlur={handleBlur}
              onChange={(event) => {
                setSaved(false);
                handleChange(event);
              }}
            />
            {dirty && (
              <PrimaryButton
                className="submit-button"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !dirty}
              >
                SAVE
              </PrimaryButton>
            )}
            {saved && <SavedBadge />}
          </form>
        )}
      </Formik>
    </ShadowBox>
  );
};

export default ChangePassword;
