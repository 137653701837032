import React from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { updateAccount } from 'actions/accountActions';
import Header from 'components/Header';
import LoginButton from 'components/LoginButton';
import Password from 'components/Password';
import PrimaryButton from 'components/PrimaryButton';
import ShadowBox from 'components/ShadowBox';
import VerifyLoggedOut from 'components/VerifyLoggedOut';
import PATHNAMES from 'constants/pathnames';
import AccountNotFoundError from 'errors/AccountNotFoundError';
import preventDefault from 'utils/preventDefault';
import validators from 'utils/validators';
import * as yup from 'yup';
import './ResetForgotPassword.scss';

const VALIDATION_SCHEMA = yup.object().shape({
  password: validators.password(),
  confirmPassword: validators.confirmPassword(),
});

const ResetForgotPassword = ({ history }) => {
  const [Error, setError] = React.useState(null);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setError(null);
      const { id, token } = queryString.parse(window.location.search);
      await updateAccount({ password: values.password }, { id, token });
      history.push(PATHNAMES.LOGIN);
    } catch (error) {
      resetForm();
      setError(() => () => (
        <div className="error-message error-label">
          {error instanceof AccountNotFoundError
            ? 'We were unable to locate an account with this email address'
            : 'Something went wrong -- try again!'}
        </div>
      ));
    }
  };

  return (
    <div className="reset-forgot-password">
      <Header showTitle={true} buttons={<LoginButton />} />
      <ShadowBox>
        <h3>RESET PASSWORD</h3>
        <VerifyLoggedOut>
          {Error && <Error />}
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={VALIDATION_SCHEMA}
          >
            {({
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={preventDefault(handleSubmit)}>
                <Password
                  label="Password"
                  name="password"
                  value={values.password}
                  disabled={isSubmitting}
                  error={
                    errors.password &&
                    touched.password &&
                    'Please enter a valid password'
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  showRequirements={true}
                />
                <Password
                  name="confirmPassword"
                  label="Confirm Password"
                  value={values.confirmPassword}
                  disabled={isSubmitting}
                  error={
                    errors.confirmPassword &&
                    touched.confirmPassword &&
                    'Password is invalid or does not match'
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="cta-wrapper">
                  <PrimaryButton
                    className="submit-button"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || !dirty}
                  >
                    RESET
                  </PrimaryButton>
                </div>
              </form>
            )}
          </Formik>
        </VerifyLoggedOut>
      </ShadowBox>
    </div>
  );
};

export default withRouter(ResetForgotPassword);
