import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import './TextField.scss';

const TextField = ({ error, ...rest }) => (
  <div className="text-field">
    <MuiTextField
      error={!!error}
      classes={{ root: 'mui-text-field' }}
      variant="outlined"
      size="small"
      {...rest}
    />
    {error && <div className="error-label">{error}</div>}
  </div>
);

export default TextField;
