import Button from '@material-ui/core/Button';
import { DateTime } from 'luxon';
import React from 'react';
import './LiveChatButton.scss';

const Description = ({ afterCutoff, startTime }) =>
  afterCutoff ? (
    <>
      <span className="nowrap error-label">Live chat with our Dietition!</span>
      <br />
      Check back soon for info on our next session!
    </>
  ) : (
    <>
      Live chat with our Dietitian on{' '}
      <span className="nowrap error-label">
        {startTime.toFormat('ccc, LLL d')} at{' '}
        {startTime.toFormat('h:mm a ZZZZ')}
      </span>
    </>
  );

const LiveChatButton = ({ liveChatDateTime, liveChatUrl }) => {
  const now = DateTime.local();
  const startTime = DateTime.fromISO(liveChatDateTime, {
    zone: 'America/New_York',
  });
  const cutoff = startTime.plus({ hours: 1 });
  const afterCutoff = now > cutoff;
  const liveChatAvailable =
    now >= startTime.minus({ minutes: 10 }) && !afterCutoff;

  return (
    <div className="live-chat">
      <div className="description">
        <Description {...{ afterCutoff, startTime }} />
      </div>
      <Button
        color="primary"
        disabled={!liveChatAvailable}
        variant="contained"
        onClick={() => window.open(liveChatUrl, '_blank')}
      >
        JOIN LIVE CHAT
      </Button>
    </div>
  );
};

export default LiveChatButton;
