import React from 'react';
import { getActivationCodes } from 'actions/adminActions';
import ActivationCodesTable from 'components/ActivationCodesTable';
import BackLink from 'components/BackLink';
import GenerateActivationCode from 'components/GenerateActivationCode';
import LoadingBox from 'components/LoadingBox';
import LoggedInHeader from 'components/LoggedInHeader';
import PATHNAMES from 'constants/pathnames';
import './ActivationCodesAdmin.scss';

const ActivationCodesAdmin = () => {
  const [activationCodes, setActivationCodes] = React.useState([]);
  const loadingActivationCodes = activationCodes.length === 0;

  React.useEffect(() => {
    (async () => {
      setActivationCodes(await getActivationCodes());
    })();
  }, [setActivationCodes]);

  return (
    <div className="activation-codes-admin">
      <LoggedInHeader />
      <BackLink to={PATHNAMES.DEFAULT} />
      <GenerateActivationCode />
      {loadingActivationCodes ? (
        <LoadingBox />
      ) : (
        <ActivationCodesTable data={activationCodes} />
      )}
    </div>
  );
};

export default ActivationCodesAdmin;
