import React from 'react';
import Link from '@material-ui/core/Link';
import PATHNAMES from 'constants/pathnames';
import './InvalidLoginMessage.scss';

const Email = ({ history }) => (
  <div className="invalid-login error-label">
    <span>You entered an incorrect email, password, or both.</span>
    <br />
    <span>
      Need an account?{' '}
      <Link className="sign-up" onClick={() => history.push(PATHNAMES.SIGN_UP)}>
        Sign up
      </Link>
    </span>
  </div>
);

export default Email;
