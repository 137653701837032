import React from 'react';
import { withRouter } from 'react-router-dom';
import { checkLoggedIn } from 'actions/accountActions';
import Feedback from 'components/Feedback';
import PATHNAMES from 'constants/pathnames';

const VerifyLoggedIn = ({
  children,
  history,
  renderLoading = () => null,
  verifyAdmin = false,
}) => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      if (await checkLoggedIn(verifyAdmin)) {
        setLoading(false);
      } else {
        history.push(PATHNAMES.LOGIN);
      }
    })();
  }, [history, loading, verifyAdmin]);

  return loading ? (
    renderLoading()
  ) : (
    <>
      {children}
      <Feedback />
    </>
  );
};

export default withRouter(VerifyLoggedIn);
