export default {
  ACCOUNTS_ADMIN: '/admin/accounts',
  ACCOUNT_SETTINGS: '/account-settings',
  ACTIVATION_CODES_ADMIN: '/admin/activation-codes',
  DEFAULT: '/',
  EDIT_ACCOUNT: '/admin/accounts/:id',
  EDIT_NUTRITION: '/admin/nutrition/:id',
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  LIVE_CHAT_VIDEOS: '/live-chat-videos',
  NUTRITION: '/nutrition',
  NUTRITION_ADMIN: '/admin/nutrition',
  RESET_FORGOT_PASSWORD: '/reset-forgot-password',
  SIGN_UP: '/sign-up',
  VIDEOS: '/videos',
  WATCH: '/watch/:id',
  WATCH_LIVE_CHAT: '/live-chat-videos/watch/:id',
};
