import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { createComment } from 'actions/nutritionActions';
import PrimaryButton from 'components/PrimaryButton';
import { Formik } from 'formik';
import React from 'react';
import validators from 'utils/validators';
import * as yup from 'yup';
import './AddComment.scss';

const VALIDATION_SCHEMA = yup.object().shape({
  comment: validators.nutritionComment(),
});

const AddComment = ({ id, onAdd }) => {
  const [error, setError] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = async ({ comment }, { resetForm }) => {
    try {
      setSubmitting(true);
      const updatedComments = await createComment(id, comment);
      onAdd(updatedComments);
      resetForm();
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setError(true);
    }
  };

  return (
    <Paper className="add-comment">
      <Formik
        initialValues={{ comment: '' }}
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              className="text-field"
              error={error}
              multiline
              disabled={submitting}
              label="Add Comment"
              variant="outlined"
              value={values.comment}
              onChange={(event) => {
                setError(false);
                setFieldValue('comment', event.target.value);
              }}
            />
            <PrimaryButton
              className="submit-button"
              type="submit"
              disabled={submitting || !values.comment}
              loading={submitting}
            >
              SUBMIT
            </PrimaryButton>
          </form>
        )}
      </Formik>
    </Paper>
  );
};

export default AddComment;
