import React from 'react';
import { Link } from 'react-router-dom';
import './BackLink.scss';

const BackLink = ({ to }) => (
  <div className="back-link">
    <Link to={to}>« Back</Link>
  </div>
);

export default BackLink;
