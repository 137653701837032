import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import './SkeletonCard.scss';

const SkeletonCard = () => {
  return (
    <Card className="skeleton-card">
      <CardMedia className="skeleton-card-thumbnail" title="Loading...">
        <div />
      </CardMedia>
      <CardContent>
        <div className="title" />
        {/* <div className="description" /> */}
      </CardContent>
    </Card>
  );
};

export default SkeletonCard;
