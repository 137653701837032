import Button from '@material-ui/core/Button';
import { getNutrition } from 'actions/nutritionActions';
import LiveChatButton from 'components/LiveChatButton';
import LoadingBox from 'components/LoadingBox';
import LoggedInHeader from 'components/LoggedInHeader';
import NutritionComments from 'components/NutritionComments';
import NutritionTheme from 'components/NutritionTheme';
import padStart from 'lodash-es/padStart';
import React from 'react';
import './Nutrition.scss';

const getDate = (date) => {
  const parsed = new Date(`${date}T00:00:00`);
  const month = padStart(String(parsed.getMonth() + 1), 2, '0');
  const day = padStart(String(parsed.getDate()), 2, '0');
  const year = String(parsed.getFullYear());
  return `${month}/${day}/${year}`;
};

const Nutrition = () => {
  const [nutrition, setNutrition] = React.useState(null);
  const loading = nutrition === null;

  React.useEffect(() => {
    (async () => {
      setNutrition(await getNutrition());
    })();
  }, []);

  return (
    <div className="nutrition">
      <NutritionTheme>
        <LoggedInHeader />
        {loading ? (
          <LoadingBox />
        ) : (
          <>
            <h3>Week of {getDate(nutrition.startDate)}</h3>
            <LiveChatButton
              liveChatDateTime={nutrition.liveChatDateTime}
              liveChatUrl={nutrition.liveChatUrl}
            />
            <div className="button-wrapper">
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  window.open(
                    `/content/${nutrition.path}/meal-plan.pdf`,
                    '_blank'
                  )
                }
              >
                Meal Plan
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  window.open(
                    `/content/${nutrition.path}/recipes.pdf`,
                    '_blank'
                  )
                }
              >
                Recipes
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  window.open(
                    `/content/${nutrition.path}/shopping-list.pdf`,
                    '_blank'
                  )
                }
              >
                Shopping List
              </Button>
            </div>
            <div className="meal-plan-image-wrapper">
              <img
                alt="meal plan"
                src={`/content/${nutrition.path}/meal-plan.png`}
              />
            </div>
            <NutritionComments id={nutrition.id} />
          </>
        )}
      </NutritionTheme>
    </div>
  );
};

export default Nutrition;
