import React from 'react';
import { getVideos } from 'actions/videoActions';
import LoadingVideos from 'components/LoadingVideos';
import LoggedInHeader from 'components/LoggedInHeader';
import VideoCard from 'components/VideoCard';
import VideoFilters from 'components/VideoFilters';
import VideoGrid from 'components/VideoGrid';
import PATHNAMES from 'constants/pathnames';
import './Videos.scss';

const Videos = () => {
  const [videos, setVideos] = React.useState([]);
  const loading = videos.length === 0;

  const [filters, setFilters] = React.useState({
    MOBILITY: true,
    WORKOUT: true,
  });

  const handleToggleFilter = (filter) =>
    setFilters({ ...filters, [filter]: !filters[filter] });

  React.useEffect(() => {
    (async () => {
      setVideos(await getVideos());
    })();
  }, []);

  return (
    <div className="videos">
      <LoggedInHeader />
      <VideoFilters filters={filters} onToggle={handleToggleFilter} />
      {loading ? (
        <LoadingVideos />
      ) : (
        <VideoGrid>
          {videos
            .filter(({ type }) => filters[type])
            .map((metadata) => (
              <VideoCard
                key={metadata.path}
                metadata={metadata}
                to={PATHNAMES.WATCH.replace(':id', metadata.id)}
              />
            ))}
        </VideoGrid>
      )}
    </div>
  );
};

export default Videos;
