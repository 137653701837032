import React from 'react';
import { getAccount, updateAccount } from 'actions/accountActions';
import ChangeEmail from 'components/AccountSettings/ChangeEmail';
import ChangePassword from 'components/AccountSettings/ChangePassword';
import BackLink from 'components/BackLink';
import LoadingBox from 'components/LoadingBox';
import LoggedInHeader from 'components/LoggedInHeader';
import PATHNAMES from 'constants/pathnames';
import './AccountSettings.scss';

const AccountSettings = () => {
  const [account, setAccount] = React.useState(null);
  const loading = account === null;

  React.useEffect(() => {
    (async () => {
      setAccount(await getAccount());
    })();
  }, []);

  const handleChange = async (data) => {
    await updateAccount(data);
    setAccount({ ...account, ...data });
  };

  return (
    <div className="account-settings">
      <LoggedInHeader />
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <BackLink to={PATHNAMES.DEFAULT} />
          <ChangeEmail value={account.email} onChange={handleChange} />
          <ChangePassword onChange={handleChange} />
        </>
      )}
    </div>
  );
};

export default AccountSettings;
