import axios from 'axios';
import memoize from 'lodash-es/memoize';
import ApiError from 'errors/ApiError';
import api from 'utils/api';

export const getVideos = memoize(async () => {
  try {
    const { data } = await api.get('/videos');
    return data;
  } catch {
    throw new ApiError();
  }
});

export const getVideo = async (id) => {
  try {
    const { data } = await api.get(`/videos/${id}`);
    return data;
  } catch {
    throw new ApiError();
  }
};

export const getDescription = async (path) => {
  try {
    const { data } = await axios.get(path);
    return data;
  } catch {
    throw new ApiError();
  }
};
