import Fab from '@material-ui/core/Fab';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FeedbackIcon from '@material-ui/icons/FeedbackOutlined';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import { submitFeedback } from 'actions/accountActions';
import PrimaryButton from 'components/PrimaryButton';
import { Field, Formik } from 'formik';
import React from 'react';
import validators from 'utils/validators';
import * as yup from 'yup';
import './Feedback.scss';

const VALIDATION_SCHEMA = yup.object().shape({
  feedback: validators.feedback(),
});

const Icon = ({ showCard, showMobile, ...rest }) => {
  if (showMobile) return <FeedbackIcon {...rest} />;
  return showCard ? <ExpandMoreIcon {...rest} /> : <ExpandLessIcon {...rest} />;
};

const Feedback = () => {
  const [sending, setSending] = React.useState(false);
  const [showCard, setShowCard] = React.useState(false);
  const showMobile = useWindowWidth() <= 600;

  const toggleCard = () => setShowCard(!showCard);

  const handleSubmit = async ({ feedback }) => {
    try {
      setSending(true);
      await submitFeedback(feedback);
      toggleCard();
      setSending(false);
    } catch (error) {
      setSending(false);
    }
  };

  return (
    <div className="feedback">
      {showCard && (
        <Paper className="feedback-card" elevation={3}>
          <h3>Feedback?</h3>{' '}
          <Formik
            initialValues={{ feedback: '' }}
            onSubmit={handleSubmit}
            validationSchema={VALIDATION_SCHEMA}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="feedback"
                  component="textarea"
                  aria-label="feedback"
                  className="feedback-textarea"
                  rows="4"
                  placeholder="We appreciate your feedback!"
                />
                <div style={{ display: 'flex' }}>
                  <div style={{ flexGrow: 1 }} />
                  <Link className="close-link" onClick={toggleCard}>
                    Close
                  </Link>
                  <PrimaryButton
                    className="submit-button"
                    type="submit"
                    disabled={sending}
                    loading={sending}
                  >
                    SUBMIT
                  </PrimaryButton>
                </div>
              </form>
            )}
          </Formik>
        </Paper>
      )}
      <Fab
        variant="extended"
        color="primary"
        aria-label="feedback"
        className="feedback-fab"
        onClick={toggleCard}
      >
        {!showMobile && <span className="feedback-label">Feedback?</span>}
        <Icon
          showCard={showCard}
          showMobile={showMobile}
          className="feedback-icon"
        />
      </Fab>
    </div>
  );
};

export default Feedback;
