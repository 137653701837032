import React from 'react';
import classNames from 'classnames';

const InvalidActivationCodeMessage = ({ className }) => (
  <div className={classNames(className, 'error-label')}>
    <span>Invalid activation code.</span>
    <br />
    <span>
      <a
        className="get-activation-code-link external"
        href="https://www.dauntlessnh.com/contact-us"
        target="_blank"
        rel="noopener noreferrer"
      >
        Contact us to get an activation code!
      </a>
    </span>
  </div>
);

export default InvalidActivationCodeMessage;
