import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import './VideoCard.scss';

const VideoCard = ({ metadata, to }) => {
  const { name, path } = metadata;

  return (
    <Card className="video-card">
      <CardActionArea
        component={Link}
        to={to}
        style={{ height: '100%', textDecoration: 'none' }}
      >
        <CardMedia
          className="video-card-thumbnail"
          image={`/content/${path}/thumbnail.png`}
          title={name}
        />
        <CardContent>
          <h5>{name}</h5>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default VideoCard;
