import React from 'react';
import orderBy from 'lodash-es/orderBy';
import DataTable from '../DataTable';
import './ActivationCodesTable.scss';

const ActivationCodesTable = ({ data }) => {
  const tableData = data.map(({ code, available, createdBy, usedBy = '' }) => ({
    code,
    available: available ? '✔︎' : '✘',
    createdBy,
    usedBy,
  }));

  return (
    <div className="activation-codes-table">
      <DataTable
        columns={[
          { title: 'CODE', field: 'code' },
          { title: 'CREATED BY', field: 'createdBy' },
          { title: 'AVAILABLE', field: 'available' },
          { title: 'USED BY', field: 'usedBy' },
        ]}
        data={orderBy(tableData, ['available'])}
        options={{ showTitle: false }}
        style={{ margin: '1em' }}
      />
    </div>
  );
};

export default ActivationCodesTable;
